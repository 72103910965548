

import React, { Component } from 'react';
import Calendar from 'react-calendar';
import CalendarComponent from './calendar.component';
import InstagramEmbed from 'react-instagram-embed'
import ReactContactForm from 'react-mail-form';

export default class contactComponent extends Component {

  render() {
    return (

      <div className="contact"><p>for business inqueries please contact:<br/>sebastianmattsson106@hotmail.com</p> 
      </div>


    );
  }
}