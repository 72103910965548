import React from "react";

export const Logo = () => {
  return (
    <div className="logo">
      <img
        src="../assets/sebbesaintblack.jpg"
        width="350em"
        height="150em"
        alt="logo"
      />
    </div>
  );
};
